(function() {
    'use strict';

    wo$(function() {

        var page = wo$('#home');
        if (!page.length) return;

        var homeController = {

            init: function() {

                WhistleOut.getAds('Broadband');
            }

        };

        homeController.init();
    });

}());
